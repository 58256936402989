<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <v-container fluid>
    <div>
      <contacts-select
        :selected-contacts="sharedTo"
        :multiple="true"
        submit-text="Partager"
        :submit-disabled="isSubmitted"
        @select="submit"
      />
    </div>
  </v-container>
</template>

<script>
  import * as Files from '@/modules/files/api'
  import ContactsSelect from '@/modules/network/components/contacts/ContactsSelect'

  export default {
    name: 'FileSharing',
    components: {ContactsSelect},

    data(){
      return {
        file: null,
        isSubmitted: false
      }
    },

    computed: {
      sharedTo(){
        if(!this.file || !this.file.shared_to) return

        return this.file.shared_to.map(v => ({uid: v.uid, full_name: v.name}))
      }
    },

    async mounted(){
      this.file = await Files.get(this.$route.params.uid).then(response => response.data)
    },

    methods: {
      async submit(contacts){
        this.isSubmitted = true
        let users = contacts.map(v => v.uid)
        await Files.share(this.file.uid, users)
        this.$router.go(-1)
      }
    }
  }
</script>

