<!--
  - Copyright (C)  E-Synaps SAS - 2020 - All Rights Reserved
  - Unauthorized copying of this file, via any medium is strictly prohibited
  - Proprietary and confidential
  -->

<template>
  <div>
    <template v-if="isLoading">
      <v-skeleton-loader v-for="i in 8" :key="i" type="list-item-avatar-three-line" class="mx-auto" />
    </template>
    <template v-else>
      <v-card v-if="multiple && selected.length" class="mx-auto elevation-x pa-2">
        <v-card-text class="pa-0">
          <v-chip-group multiple column>
            <v-chip v-for="val in selected" :key="val.uid" close pill @click:close="removeFromSelected(val)">
              <user-avatar :user="val" left />
              {{ val.full_name }}
            </v-chip>
          </v-chip-group>
        </v-card-text>

        <div class="d-flex justify-center mt-3">
          <v-btn v-if="selected.length > 0" color="primary" small :disabled="submitDisabled" @click="submit">
            {{ submitText }}
          </v-btn>
        </div>
      </v-card>

      <v-card class="elevation-x mt-2">
        <template v-for="(contact, index) in contacts">
          <v-list-item v-show="!isSelected(contact)" :key="contact.uid" @click="select(contact)">
            <v-list-item-avatar size="50">
              <user-avatar :user="contact" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="contact.full_name" />
              <v-list-item-subtitle class="primary--text" v-text="getJob(contact.job)" />
              <address-displayer :address="getAddress(contact.main_address)" />
            </v-list-item-content>
          </v-list-item>

          <v-divider v-if="index + 1 < contacts.length" :key="index" class="my-0" />
        </template>
      </v-card>
    </template>
  </div>
</template>

<script>
  import AddressDisplayer from '@/modules/core/components/AddressDisplayer'
  import {empty} from 'esyn-utils/src/utils/variables'
  import {debounce} from 'throttle-debounce'
  import UserAvatar from '@/modules/core/layout/UserAvatar'
  import {search} from '@/modules/professionals/api'

  export default {
    name: 'ContactsSelect',
    components: {UserAvatar, AddressDisplayer},

    props: {
      multiple: {
        type: Boolean
      },
      submitText: {
        type: String
      },
      submitDisabled: {
        type: Boolean
      },
      selectedContacts: {
        type: Array,
        default() {
          return []
        }
      }
    },

    data() {
      return {
        isLoading: true,
        selected: this.selectedContacts,
        search: {
          isLoading: false,
          terms: '',
          results: [],
          global: false
        }
      }
    },

    computed: {
      contacts() {
        return this.search.results.length ? this.search.results : this.$store.state.contacts.items
      }
    },

    watch: {
      ['search.terms']: debounce(500, async function (val) {
        if (empty(val)) {
          this.search.results = []
          return
        }

        this.executeSearch()
      }),

      ['search.global']() {
        this.executeSearch()
      }
    },

    async created() {
      if (!this.$store.getters['contacts/isInitialized'] || this.$store.state.contacts.spaceFiltered) {
        this.$store.commit('contacts/setSpaceFiltered', false)
        await this.$store.dispatch('contacts/fetchPage')
      }

      this.isLoading = false
    },

    methods: {
      select(contact) {
        if (this.multiple) {
          this.selected.push(contact)
        } else {
          this.$emit('select', contact)
        }
      },

      isSelected(contact) {
        return !!this.selected.find(val => {
          return val.uid === contact.uid
        })
      },

      removeFromSelected(contact) {
        let index = this.selected.findIndex(val => {
          return val.uid === contact.uid
        })

        this.selected.splice(index, 1)
      },

      submit() {
        this.$emit('select', this.selected)
      },

      getAddress(address) {
        if (empty(address) || empty(address.city) || empty(address.zip_code)) {
          return null
        }

        let city = typeof address.city === 'object' ? address.city.name : address.city
        let zipCode = typeof address.zip_code === 'object' ? address.zip_code.name : address.zip_code

        return {
          address1: address.address1,
          city: {name: city},
          zip_code: {name: zipCode}
        }
      },

      getJob(job) {
        if (empty(job)) {
          return null
        }

        if (typeof job === 'object') {
          return job.name
        }

        return job
      },

      async executeSearch() {
        this.search.isLoading = true

        let filters = {search_term: this.search.terms}
        if (!this.search.global) {
          filters.mycontacts = true
        }
        let response = await search(1, 10, filters)
        this.search.results = response.list

        this.search.isLoading = false
      }
    }
  }
</script>
